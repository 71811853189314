.events-section {
  flex-direction: column;
}

@media (max-width: 959px) {
  .new-events {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    grid-gap: 0;
  }

  .new-events__item:nth-child(1) {
    width: 100%;
  }

  .container--posts-slider {
    padding: 0 24px;
  }

  .posts-slider {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  .posts-slider .posts-slide {
    flex-shrink: 0;
    width: 62.3%;
    margin: 24px 12px 0;
  }

  .posts-slider .posts-slide:nth-child(1) {
    margin-left: 0;
  }

  .posts-slider .posts-slide:nth-last-child(1) {
    margin-right: 0;
  }
}
